import { render, staticRenderFns } from "./ModalAlertNdvi.vue?vue&type=template&id=2ca13dd9&scoped=true"
import script from "./ModalAlertNdvi.vue?vue&type=script&lang=js"
export * from "./ModalAlertNdvi.vue?vue&type=script&lang=js"
import style0 from "./ModalAlertNdvi.vue?vue&type=style&index=0&id=2ca13dd9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca13dd9",
  null
  
)

export default component.exports