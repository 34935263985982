<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('talhoes.mapa') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items
            v-if="paths.length > 2 && hasPermission($p.plot.create)"
          >
            <v-btn
              :disabled="exceededTotalArea || invalidPolygonGeometry"
              dark
              text
              @click="submit"
              :loading="saving"
              >{{ $t('salvar') }}</v-btn
            >
          </v-toolbar-items>
        </v-toolbar>
        <v-item-group>
          <v-container class="container_map">
            <v-row>
              <v-col cols="8" sm="8" md="4">
                <v-text-field
                  v-model="talhao.nome"
                  :label="$t('talhoes.nome_do_talhao')"
                  required
                  type="text"
                  :error-messages="nomeValidate"
                />
              </v-col>
              <v-col cols="4" sm="8" md="2" align-self="center">
                <div v-if="talhao.area > 0">
                  {{ $t('area') }}: {{ convertedArea }}
                  {{ $unitMeasures['area'][$currentLanguage()] }}
                </div>
              </v-col>
              <v-col
                cols="6"
                v-if="!$vuetify.breakpoint.mobile"
                md="6"
                class="text-center"
              >
                <div v-if="paths.length > 0 && hasPermission($p.plot.delete)">
                  <v-btn dark text class="green" @click="remove">
                    <v-icon>mdi-restart</v-icon>{{ $t('talhoes.remove_ponto') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="6" md="2">
                <v-text-field
                  :label="$t('talhoes.search_latitude')"
                  type="text"
                  :error-messages="nomeValidate"
                  v-model.number="search.lat"
                  @change="updateMapCenter"
                />
              </v-col>
              <v-col cols="6" sm="6" md="2">
                <v-text-field
                  :label="$t('talhoes.search_longitude')"
                  type="text"
                  :error-messages="nomeValidate"
                  v-model.number="search.lng"
                  @change="updateMapCenter"
                />
              </v-col>
              <v-col
                v-if="!$vuetify.breakpoint.mobile"
                cols="8"
                md="8"
                justify="center"
                align="center"
              >
                <v-alert
                  v-if="paths.length < 3"
                  class="mx-auto mb-0"
                  color="orange"
                  text
                  dense
                  type="error"
                >
                  {{ $t('talhoes.alert_salvar_talhao') }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row
              v-if="$vuetify.breakpoint.mobile && hasPermission($p.plot.delete)"
            >
              <v-col cols="12" class="text-center">
                <div v-if="paths.length > 0">
                  <v-btn dark text class="green" @click="remove">
                    <v-icon>mdi-restart</v-icon>{{ $t('talhoes.remove_ponto') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="invalidPolygonGeometry">
              <v-col cols="12" align-self="center" class="pb-0">
                <v-alert
                  class="mx-auto mb-0"
                  color="red"
                  text
                  dense
                  type="error"
                >
                  <span>{{ $t('exception.plot.invalid_geometry') }}</span>
                </v-alert>
              </v-col>
            </v-row>

            <v-row v-else-if="exceededTotalArea">
              <v-col cols="12" align-self="center" class="pb-0">
                <v-alert
                  class="mx-auto mb-0"
                  color="orange"
                  text
                  dense
                  type="error"
                >
                  <span>{{ $t('talhoes.area_exceeded') }}</span>
                  <a
                    href="https://api.whatsapp.com/send?phone=5551993519481"
                    target="_blank"
                  >
                    +55 51 99351-9481
                    <v-img
                      alt="whatsapp"
                      class="whatsapp-icon"
                      max-height="20px"
                      max-width="20px"
                      src="img/logos/logo-whatsapp.svg"
                    />
                  </a>
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.mobile">
              <v-col cols="12" align-self="center" class="pb-0">
                <v-alert
                  v-if="paths.length < 3"
                  class="mx-auto mb-0"
                  color="orange"
                  text
                  dense
                  type="error"
                >
                  {{ $t('talhoes.alert_salvar_talhao') }}
                </v-alert>
              </v-col>
            </v-row>

            <v-row justify="center" align="center">
              <v-col cols="12" class="pt-0">
                <gmap-map
                  ref="talhaoMapRef"
                  v-show="talhao.latitude"
                  :center="center"
                  @center_changed="
                    (event) => {
                      this.search.lat = event.lat()
                      this.search.lng = event.lng()
                    }
                  "
                  :zoom="15"
                  style="width: 100%; height: 500px"
                  @click="mark"
                  map-type-id="hybrid"
                  :options="{ fullscreenControl: true }"
                >
                  <v-col v-for="plot in alreadyRegisteredPlots" :key="plot.id">
                    <polygon-plot :plot="plot"></polygon-plot>
                  </v-col>
                  <v-col>
                    <gmap-polygon
                      :paths="paths"
                      :editable="isPlotEditable"
                      @paths_changed="updateEdited($event)"
                      :options="polygon.polygonOptions"
                    >
                    </gmap-polygon>
                  </v-col>
                </gmap-map>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars
const google_geometry =
  'https://maps.googleapis.com/maps/api/js?key=' +
  process.env.VUE_APP_GOOGLE_MAPS_KEY +
  '&libraries=libraries=places,drawing,geometry'
</script>

<script type="text/javascript" src="google_geometry"></script>

<script>
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import PolygonPlot from './PolygonPlot.vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  name: 'ModalMaps',

  mixins: [validationMixin, PermissionMixin],

  components: {
    PolygonPlot,
  },

  validations: {
    talhao: {
      nome: { required },
    },
  },

  props: {
    plot: {
      type: Object,
      required: true,
    },
    plots: {
      type: Array,
      default: () => [],
    },
    accountsPlotsArea: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      talhao: {
        id: null,
        nome: null,
        area: null,
        latitude: null,
        longitude: null,
      },
      center: {
        lat: null,
        lng: null,
      },
      search: {
        lat: null,
        lng: null,
      },
      paths: [],
      saving: false,
      polygon: {
        polygonOptions: {
          strokeColor: null,
          fillColor: null,
        },
      },
      startedArea: 0,
      controlRemovePoint: {
        button: {
          isActive: false,
          indexRef: null,
        },
        buttonEvent: {
          isActive: false,
        },
        keyEvent: {
          isActive: false,
        },
      },
    }
  },

  mounted() {
    this.mountPlot()
    this.startedArea = this.plot?.area ?? 0
    this.initEventsFromMap()
  },

  watch: {
    plot: {
      handler(newPlot) {
        if (newPlot) {
          this.dialog = true
        }
      },
      deep: true,
      immediate: true,
    },
    paths() {
      this.polygon.polygonOptions.strokeColor = this.getColor()
      this.polygon.polygonOptions.fillColor = this.getColor()
    },
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    nomeValidate() {
      return this.$v.talhao.nome.$dirty && !this.$v.talhao.nome.required
        ? [this.$t('campo_obrigatorio')]
        : []
    },
    convertedArea() {
      return convertToTargetUnit(this.talhao.area, 'area')
    },
    mapCoordinate() {
      return this.search
    },
    alreadyRegisteredPlots() {
      return this.plots.filter((plot) => {
        return plot.id !== this.talhao.id
      })
    },
    exceededTotalArea() {
      const tolerance = 1.05
      const accountArea = this.accountsPlotsArea - this.startedArea
      const totalAreaModified = accountArea + parseFloat(this.talhao?.area ?? 0)

      return totalAreaModified > this.currentUser.total_area_account * tolerance
    },
    isPlotEditable() {
      return this.hasPermission(this.$p.plot.edit) !== undefined
    },
    invalidPolygonGeometry() {
      if (this.paths.length < 3) return false
      const geometryFactory = new this.$jsts.GeometryFactory()

      const linearRing = geometryFactory.createLinearRing(
        this.createCoordinates()
      )

      const polygon = geometryFactory.createPolygon(linearRing)

      const graph = new this.$jsts.GeometryGraph(0, polygon)

      const validation = new this.$jsts.valid.ConsistentAreaTester(graph)

      return !validation.isNodeConsistentArea()
    },
  },

  methods: {
    ...mapActions('plot', ['createPlot', 'updatePlot']),
    updateMapCenter() {
      this.center = {
        ...this.search,
      }
    },
    mountPlot() {
      this.talhao = { ...this.plot }

      this.talhao.latitude = parseFloat(this.plot.latitude)
      this.talhao.longitude = parseFloat(this.plot.longitude)

      this.search.lat = this.talhao?.latitude
      this.search.lng = this.talhao?.longitude

      this.updateMapCenter()

      this.fillPolygon(this.talhao.coordenadas_lat, this.talhao.coordenadas_lng)
    },
    open() {
      this.dialog = true
    },
    getColor() {
      if (this.invalidPolygonGeometry) return 'red'

      if (this.exceededTotalArea) return 'orange'

      return '#4caf50'
    },
    createCoordinates() {
      const coordinates = this.paths.map(
        (p) => new this.$jsts.Coordinate(p.lat, p.lng)
      )

      coordinates.push(coordinates[0])

      return coordinates
    },
    fillPolygon(coordenadas_lat, coordenadas_lng) {
      if (!coordenadas_lat || !coordenadas_lng) {
        this.paths = []
        return
      }

      let lat = coordenadas_lat.split('|')
      let lng = coordenadas_lng.split('|')
      let polygon = []

      for (let x = 0; x < lat.length; x++) {
        polygon.push({ lat: parseFloat(lat[x]), lng: parseFloat(lng[x]) })
      }

      this.paths = polygon
      this.calcularArea()
    },
    close() {
      this.$emit('refresh')
      this.dialog = false
    },
    updateEdited(mvcArray) {
      let paths = []

      for (let i = 0; i < mvcArray.getLength(); i++) {
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j)
          paths.push({ lat: point.lat(), lng: point.lng() })
        }

        this.paths = paths
      }

      this.calcularArea()
    },
    mark(event) {
      this.paths.push({ lat: event.latLng.lat(), lng: event.latLng.lng() })
      this.calcularArea()
      this.initEventsFromMap()
    },
    remove() {
      this.paths.pop()
      this.calcularArea()
      this.initEventsFromMap()
    },
    calcularArea() {
      if (this.paths.length >= 3) {
        const googlePaths = this.paths.map(
          (p) => new google.maps.LatLng(p.lat, p.lng)
        )
        const googlePolygon = new google.maps.Polygon({ paths: googlePaths })
        const area = google.maps.geometry.spherical.computeArea(
          googlePolygon.getPath()
        )
        this.talhao.area = (area / 10000).toFixed(2) // hectare = 10.000m2
      }
    },
    async submit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.saving = true

        let lat = ''
        let lng = ''
        let s = ''
        this.paths.forEach((element) => {
          lat += s + element.lat
          lng += s + element.lng
          s = '|'
        })

        this.talhao.latitude = this.paths[0].lat
        this.talhao.longitude = this.paths[0].lng

        this.talhao.coordenadas_lat = lat
        this.talhao.coordenadas_lng = lng

        const action = this.talhao.id ? this.updatePlot : this.createPlot

        try {
          await action(this.talhao)

          this.$root.$emit(
            'notify',
            'success',
            this.$t('talhoes.salvar.sucesso')
          )

          this.saving = false
          this.close()
        } catch (e) {
          this.saving = false
        }
      }
    },
    isFullScreenMap() {
      return !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      )
    },
    keyDownActionEvent(event) {
      if (
        this.paths.length &&
        event.ctrlKey &&
        event.key.toLowerCase() === 'z'
      ) {
        this.remove()
      }
    },
    buttonRemovePointEvent() {
      !this.isFullScreenMap()
        ? this.controlRemovePointButton()
        : this.controlRemovePointButton(true)
    },
    initEventsFromMap() {
      if (this.paths.length > 0) {
        // add event keydown for 'ctrl + z' to remove point
        if (!this.controlRemovePoint.keyEvent.isActive) {
          window.addEventListener('keydown', this.keyDownActionEvent, false)

          this.controlRemovePoint.keyEvent.isActive = true
        }

        // added event on expand
        if (!this.controlRemovePoint.buttonEvent.isActive) {
          ;[
            'fullscreenchange',
            'webkitfullscreenchange',
            'mozfullscreenchange',
          ].map((e) =>
            document.addEventListener(e, this.buttonRemovePointEvent, false)
          )

          this.controlRemovePoint.buttonEvent.isActive = true
        }
        if (this.isFullScreenMap()) {
          this.controlRemovePointButton(true)
        }
      }

      if (this.paths.length === 0) {
        // remove event keydown CTRL + z
        if (!this.controlRemovePoint.keyEvent.isActive) {
          window.removeEventListener('keydown', this.keyDownActionEvent)
          this.controlRemovePoint.keyEvent.isActive = false
        }
      }

      if (!this.isFullScreenMap() || this.paths.length === 0) {
        this.controlRemovePointButton()
      }
    },
    controlRemovePointButton(fullScreenMode = false) {
      // add the button in the map
      if (
        fullScreenMode &&
        !this.controlRemovePoint.button.isActive &&
        this.paths.length > 0
      ) {
        this.controlRemovePoint.button.isActive = true

        this.$refs.talhaoMapRef?.$mapPromise.then((map) => {
          const controlButtonRemovePoint = document.createElement('button')
          controlButtonRemovePoint.style.backgroundColor = '#fff'
          controlButtonRemovePoint.style.border = '2px solid #fff'
          controlButtonRemovePoint.style.borderRadius = '3px'
          controlButtonRemovePoint.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
          controlButtonRemovePoint.style.color = 'rgb(25,25,25)'
          controlButtonRemovePoint.style.cursor = 'pointer'
          controlButtonRemovePoint.style.fontFamily = 'Roboto,Arial,sans-serif'
          controlButtonRemovePoint.style.fontSize = '16px'
          controlButtonRemovePoint.style.lineHeight = '38px'
          controlButtonRemovePoint.style.margin = '8px 0 22px'
          controlButtonRemovePoint.style.padding = '0 5px'
          controlButtonRemovePoint.style.textAlign = 'center'

          controlButtonRemovePoint.textContent = this.$t('talhoes.remove_ponto')
          controlButtonRemovePoint.type = 'button'

          controlButtonRemovePoint.addEventListener('click', () =>
            this.remove()
          )

          map.controls[google.maps.ControlPosition.TOP_CENTER].push(
            controlButtonRemovePoint
          )

          this.controlRemovePoint.button.indexRef = map.controls[
            google.maps.ControlPosition.TOP_CENTER
          ].indexOf(controlButtonRemovePoint)
        })
      }
      // remove button
      if (!fullScreenMode && this.controlRemovePoint.button.isActive) {
        this.$refs.talhaoMapRef?.$mapPromise.then((map) => {
          map.controls[google.maps.ControlPosition.TOP_CENTER].removeAt(
            this.controlRemovePoint.button.indexRef
          )
        })
        this.controlRemovePoint.button.isActive = false
      }
    },
  },
}
</script>

<style scoped>
.container_map {
  max-width: 100% !important;
}
.whatsapp-icon {
  display: inline-block;
  vertical-align: sub;
}
</style>
