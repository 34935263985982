<template>
  <v-dialog :value="dialog" max-width="500" persistent>
    <v-card class="modal-alert">
      <v-card-text class="modal-content">
        <div class="box-icon">
          <font-awesome-icon icon="vector-square" />
        </div>

        <h3>{{ $t('alert_ndvi.title') }}</h3>

        <article>
          {{ $t('alert_ndvi.message') }}
        </article>
      </v-card-text>

      <v-card-actions class="modal-footer">
        <v-spacer></v-spacer>
        <v-btn color="#39AF49" @click="close">{{
          $t('alert_ndvi.confirm')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalAlertNdvi',

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.modal-alert .modal-content {
  padding: 32px !important;
}

.modal-alert .modal-content h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1a2b46;
  text-align: center;
  margin-top: 16px;
}
.modal-alert .modal-content article {
  font-size: 16px;
  font-weight: 400;
  color: #5b6459;
  text-align: center;
  margin-top: 8px;
}

.box-icon {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #d8f2dc;
  border-radius: 100%;
}

.box-icon svg {
  color: #39af49;
}

.modal-footer {
  padding: 16px 24px !important;
  border-top: 1px solid #e6e9e6;
}
.modal-footer button {
  height: 40px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
  padding: 12px !important;
}
</style>
