<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      persistent
      width="800"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template #activator="{ on }">
        <div v-on="on" style="height: 100% !important; width: 100% !important">
          <slot></slot>
        </div>
      </template>
      <v-card>
        <v-toolbar color="blue-grey darken-3">
          <v-toolbar-title style="color: white"
            >{{ $t('semeadura.semeadura') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div>
          <loader v-if="loadingSemeadura"></loader>
          <v-stepper v-model="step" v-else>
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">
                {{ $t('semeadura.sowing_data') }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2">
                {{ $t('semeadura.operation_data') }}
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <div>
                  <form @keydown.enter="submit">
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-autocomplete
                          ref="semente"
                          v-model="form.semente"
                          :items="sementesFilterPlanned"
                          item-value="semente_id"
                          item-text="cultivar"
                          single-line
                          :label="$t('cultivar.cultivar') + $t('required')"
                          return-object
                          :error-messages="getErrors('semente')"
                          :rules="autocompleteRules"
                          :disabled="isEditing"
                          @change="updateCycle"
                          required
                        >
                          <template slot="item" slot-scope="data">
                            <v-list>
                              <v-list-item-content
                                :class="{
                                  added: data.item.added === true,
                                }"
                              >
                                <div>{{ data.item.cultivar }}</div>
                                <div
                                  v-if="data.item.recomendado != 1"
                                  class="caption"
                                >
                                  {{ $t('cultivar.not_recommended') }}
                                </div>
                              </v-list-item-content>
                            </v-list>
                          </template>
                        </v-autocomplete>

                        <v-autocomplete
                          v-model="form.seedClassId"
                          :items="seedClasses"
                          item-value="id"
                          item-text="value"
                          single-line
                        >
                        </v-autocomplete>

                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              :label="
                                $t('semeadura.estimated_cycle_in_days') +
                                $t('required')
                              "
                              v-model="form.estimated_cycle"
                              type="number"
                              max="300"
                              :error-messages="getErrors('estimated_cycle')"
                              :rules="estimatedCycleRules"
                              :disabled="isEditing"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              :label="
                                $t('semeadura.germinative_power_in_percentage')
                              "
                              v-model="form.germinative_power"
                              type="number"
                              :min="vProps.germinative_power.min"
                              :max="vProps.germinative_power.max"
                              :error-messages="getErrors('germinative_power')"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              :label="$t('semeadura.seed_vigor_in_percentage')"
                              v-model="form.seed_vigor"
                              type="number"
                              :min="vProps.seed_vigor.min"
                              :max="vProps.seed_vigor.max"
                              :error-messages="getErrors('seed_vigor')"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="my-n8" v-if="!isUSA">
                          <v-col cols="12" sm="12">
                            <v-radio-group
                              row
                              v-model="form.origem"
                              :mandatory="false"
                            >
                              <template #label>
                                <div>
                                  {{ $t('semeadura.origin') }}:
                                  {{ $t('required') }}
                                </div>
                              </template>
                              <v-radio
                                :label="$t('bought')"
                                value="comprado"
                              ></v-radio>
                              <v-radio
                                :label="$t('own')"
                                value="proprio"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" sm="6" md="8" no-gutters>
                        <div
                          v-if="form.semente && isSoybean && seedsInfo"
                          style="color: grey"
                        >
                          <p v-if="!isUSA">
                            <b>{{ $t('semeadura.era_sowing') }}</b>

                            {{
                              form.semente.semeadura_ini
                                ? `${form.semente.semeadura_ini.formatDate()}
                          a ${form.semente.semeadura_fim.formatDate()}`
                                : $t('exception.region.no_recommendation')
                            }}
                          </p>
                          <p>
                            <b>{{ $t('cultivar.ciclo') }}:</b>
                            {{ `${form.estimated_cycle} ${$t('days')}` }}
                          </p>
                          <p v-if="isSoybean && !isUSA">
                            <b>{{ $t('soybean.growth_habit') }}:</b>
                            <span v-if="form.semente.habito">{{
                              $t(
                                'cultivar.growth_habit.' +
                                  form.semente.habito.toLowerCase()
                              )
                            }}</span>
                          </p>
                          <p v-if="!isUSA">
                            <b>{{ $t('soybean.population') }}:</b>
                            {{
                              $t('_to_', [
                                form.semente.populacao_min,
                                form.semente.populacao_max,
                              ])
                            }}
                            {{ $t('thousand') }}
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="6" md="4" no-gutters>
                        <div
                          v-if="form.semente && isSoybean"
                          style="color: grey"
                        >
                          <p v-if="!isUSA">
                            <b>INOX:</b>
                            {{ form.semente.inox ? $t('yes') : $t('no') }}
                          </p>
                          <p>
                            <b>{{ $t('cultivar.gmr') }}:</b>
                            {{ form.semente.grupo }}
                          </p>
                          <p v-if="!isUSA">
                            <b>OGM:</b> {{ form.semente.ogm }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </form>
                </div>
                <v-col class="d-flex justify-end btn-lg float-right">
                  <v-btn @click="close" text>
                    {{ $t('cancelar') }}
                  </v-btn>
                  <v-btn color="primary" @click="validateFirstStep">
                    {{ $t('next') }}
                  </v-btn>
                </v-col>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-select
                      v-model="form.talhao"
                      :key="selectKey"
                      :items="plots"
                      item-value="id"
                      item-text="nome"
                      :label="$t('talhoes.plot') + ' ' + $t('required')"
                      return-object
                      :error-messages="getErrors('talhao')"
                      @input="checkPlot"
                      :disabled="isEditing"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-menu
                      ref="menu_realizada"
                      v-model="menu_realizada"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="date_realizada_formatted"
                          :label="
                            $t('semeadura.date_realized') + ' ' + $t('required')
                          "
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="getErrors('date_realizada')"
                          :disabled="isSeedingDateDisabled"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="form.date_realizada"
                        show-current
                        no-title
                        scrollable
                        close-on-content-click
                        :return-value.sync="form.date_realizada"
                        :color="
                          isRecommendedDate ? 'primary' : 'orange darken-2'
                        "
                        :locale="getLocale"
                        :allowed-dates="allowedDates"
                        :min="formatDateISO(minDate)"
                        :max="formatDateISO(maxDate)"
                        :events="notRecommendedDates"
                      >
                        <v-chip small color="orange" v-if="!isRecommendedDate">
                          {{ $t('sowing.out_of_time') }}
                        </v-chip>
                      </v-date-picker>
                    </v-menu>
                    <v-alert
                      v-if="!isRecommendedDate"
                      small
                      color="orange"
                      type="warning"
                      dense
                      text
                      >{{ $t('sowing.out_of_time') }}</v-alert
                    >
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-menu
                      ref="menu_emergencia"
                      v-model="menu_emergencia"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="date_emergencia_formatted"
                          :label="$t('emergency_date') + ' ' + $t('required')"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="getErrors('date_emergencia')"
                          :disabled="isEmergencyDateDisabled"
                          :loading="loadingDateEmergencia"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.date_emergencia"
                        no-title
                        scrollable
                        close-on-content-click
                        :return-value.sync="form.date_emergencia"
                        :disabled="!form.date_realizada"
                        :locale="getLocale"
                        :max="formatDateISO(maxEmergencyDate)"
                        :min="formatDateISO(minDateEmergencia)"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12">
                    <converter-field
                      :label="$t('semeadura.sown_area')"
                      v-model="form.area_semeada"
                      :required="true"
                      :errors="getErrors('area_semeada')"
                      :disabled="!hasPlotSelected"
                      unit-measure="area"
                      :key="$currentLanguage()"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12">
                    <converter-field
                      ref="quantidade_semeada"
                      :label="$t('semeadura.sown_quantity')"
                      v-model="form.quantidade_semeada"
                      :errors="getErrors('quantidade_semeada')"
                      :required="!isSoybean"
                      :disabled="!hasPlotSelected"
                      unit-measure="density"
                      :key="$currentLanguage()"
                    />

                    <p v-if="form.talhao" style="color: grey">
                      <b>
                        {{ $t('talhoes.plot') }} ({{
                          $unitMeasures['area'][$currentLanguage()]
                        }}):
                      </b>
                      {{ areaTalhao }}
                    </p>
                    <p v-if="form.talhao" style="color: grey">
                      <b>
                        {{ $t('talhoes.rest_area_plot') }} ({{
                          $unitMeasures['area'][$currentLanguage()]
                        }}):
                      </b>
                      {{ remainingAreaAfterPlanting }}
                    </p>
                    <p class="ml-0" style="color: grey">
                      <b>
                        {{ $t('talhoes.quantity_total') }} ({{
                          $unitMeasures['weight_kg'][$currentLanguage()]
                        }}):
                      </b>
                      {{ totalSemeado }}
                    </p>
                  </v-col>
                </v-row>

                <v-autocomplete
                  v-model="form.seederSystemId"
                  :items="seederSystems"
                  item-value="id"
                  item-text="value"
                  single-line
                >
                </v-autocomplete>

                <v-row>
                  <v-col :cols="isUSA ? 12 : 6">
                    <converter-field
                      :label="$t('semeadura.line_spacing')"
                      v-model="form.line_spacing"
                      :errors="getErrors('line_spacing')"
                      unit-measure="length_in"
                      :key="$currentLanguage()"
                    />
                  </v-col>

                  <v-col cols="6" v-if="!isUSA">
                    <converter-field
                      :label="$t('semeadura.population_lines')"
                      v-model="form.population_lines"
                      :errors="getErrors('population_lines')"
                      unit-measure="length_ft"
                      :key="$currentLanguage()"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" sm="12">
                    <converter-field
                      :label="$t('semeadura.operating_speed')"
                      v-model="form.operating_speed"
                      :errors="getErrors('operating_speed')"
                      unit-measure="speed"
                      :key="$currentLanguage()"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <div>
                      {{ $t('did') }}
                      <strong class="gray--text">{{
                        $t('soybean.seed_tratament')
                      }}</strong
                      >?
                    </div>
                    <v-radio-group
                      row
                      v-model="form.seed_treatment"
                      :mandatory="false"
                      style="margin: 0px 7px"
                    >
                      <v-radio
                        :label="$t('yes')"
                        color="success"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        :label="$t('no')"
                        color="success"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="6">
                    <div>
                      {{ $t('used') }}
                      <strong class="gray--text">{{
                        $t('semeadura.inoculant')
                      }}</strong
                      >?
                    </div>
                    <v-radio-group
                      row
                      v-model="form.inoculant"
                      :mandatory="false"
                      style="margin: 0px 0px"
                    >
                      <v-radio
                        :label="$t('yes')"
                        color="success"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        :label="$t('no')"
                        color="success"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-col class="d-flex justify-end btn-lg float-right">
                  <v-btn @click="step = 1" text>
                    {{ $t('previous') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="submit"
                    :loading="loadingSubmit || loadingSemeadura"
                  >
                    {{ $t('salvar') }}
                  </v-btn>
                </v-col>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </v-card>
    </v-dialog>

    <modal-unused-area
      v-if="dialogUnusedArea"
      :dialog="dialogUnusedArea"
      :on-close="closeModalUnusedArea"
      :open-config-area="openConfigArea"
    />

    <modal-config-area
      v-if="dialogConfigArea"
      :dialog="dialogConfigArea"
      @close="closeModalConfigArea"
    />

    <warning-modal
      :is-open="showWarningModal"
      @close="showWarningModal = false"
    />
  </v-row>
</template>

<script>
import Loader from '@/components/Loaders/Loader.vue'
import ConverterField from '@/components/Form/ConverterField.vue'
import { validationMixin } from 'vuelidate'
import { logEvent, events } from '@/services/analytics'
import {
  inRange,
  maxValue,
  notEmpty,
  notNull,
} from '@/components/Form/validations'
import api from '@/services/api'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { currentLocale, formatDecimal } from '@/language'
import { convertToTargetUnit } from '@/components/Form/converter'
import WarningModal from './WarningModal.vue'
import ModalUnusedArea from './ModalUnusedArea'
import ModalConfigArea from '@/modules/menu/components/modals/ModalConfigArea.vue'

const validationsProps = {
  germinative_power: { min: 0, max: 100, type: '' },
  seed_vigor: { min: 0, max: 100, type: '' },
  line_spacing: { min: 10, max: 150, type: 'length_in' },
  population_lines: {
    wheat: { min: 1, max: 200, type: 'length_ft' },
    soybean: { min: 1, max: 25, type: 'length_ft' },
  },
  operating_speed: {
    wheat: { min: 1, max: 30, type: 'speed' },
    soybean: { min: 1, max: 20, type: 'speed' },
  },
  area_semeada: { min: 0, type: 'area' },
}

const initialFormState = {
  semente: null,
  seedClassId: null,
  estimated_cycle: null,
  germinative_power: null,
  seed_vigor: null,
  origem: 'comprado',
  talhao: null,
  date_realizada: null,
  date_emergencia: null,
  area_semeada: null,
  quantidade_semeada: null,
  seederSystemId: null,
  line_spacing: null,
  population_lines: null,
  operating_speed: null,
  seed_treatment: null,
  inoculant: null,
}

export default {
  name: 'ModalSemeadura',

  components: {
    Loader,
    ConverterField,
    ModalUnusedArea,
    ModalConfigArea,
    WarningModal,
  },

  props: {
    semeadura: {
      type: Object,
      default: () => null,
    },

    dialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: { ...initialFormState },
      selectKey: 0,

      step: 1,
      seedClasses: [],
      seederSystems: [],
      sementesSelect: [],
      menu_realizada: false,
      menu_emergencia: false,
      tolerancia: process.env.VUE_APP_DATA_SEMEADURA_TOLERANCIA,
      autocompleteRules: [
        (v) => !!v || this.$t('semeadura.cadastro.selecione_cultivar'),
      ],
      estimatedCycleRules: [
        (v) => !!v || this.$t('campo_obrigatorio'),
        (v) =>
          (v && v > 0) ||
          this.$t('semeadura.cadastro.error.estimated_cycle_min'),
      ],
      loadingSemeadura: false,
      loadingSubmit: false,
      loadingDateEmergencia: false,
      trySubmit: false,
      showWarningModal: false,
      dialogUnusedArea: false,
      dialogConfigArea: false,
    }
  },

  async created() {
    await this.setSeedClasses()
    await this.setSeederSystems()
    const params = {
      farmId: this.currentFarmId,
      params: 'created_at:desc&enabled=true',
    }
    await this.getPlots(params)
  },

  mixins: [validationMixin],

  validations() {
    const cropSelected = this.isSoybean ? 'soybean' : 'wheat'

    return {
      form: {
        semente: { required: notNull() },
        talhao: { required: notNull() },
        area_semeada: {
          required: notEmpty(),
          inRange: inRange({
            min: 0,
            max: this.remainingArea,
            type: 'area',
          }),
        },
        quantidade_semeada: { required: this.isSoybean ? false : notEmpty() },
        date_realizada: { required: notNull() },
        date_emergencia: { required: notNull() },
        estimated_cycle: { required: notEmpty() },
        germinative_power: {
          inRange: inRange(validationsProps.germinative_power),
        },
        seed_vigor: {
          inRange: inRange(validationsProps.seed_vigor),
          validateMaxValue: maxValue(this.form?.germinative_power),
        },
        line_spacing: {
          inRange: inRange(validationsProps.line_spacing),
        },
        population_lines: {
          inRange: inRange(validationsProps.population_lines[cropSelected]),
        },
        operating_speed: {
          inRange: inRange(validationsProps.operating_speed[cropSelected]),
        },
      },
    }
  },

  computed: {
    ...mapGetters('user', ['currentUser', 'isUSA']),
    ...mapGetters('cultivares', ['varietiesByCrop']),
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),
    ...mapGetters('farms', ['currentFarm', 'currentFarmId']),
    ...mapGetters('crops', ['isSoybean']),
    ...mapGetters('semeaduras', ['sementes', 'areaRestante', 'emergencia']),
    ...mapGetters('plot', ['plots']),
    vProps() {
      return validationsProps
    },
    remainingArea() {
      // Standard Unit (Ha)
      const areaAtual =
        this.semeadura && !this.semeadura.colheita ? this.semeadura.area : 0
      return this.areaRestante + parseFloat(areaAtual)
    },
    remainingAreaAfterPlanting() {
      const area = convertToTargetUnit(
        this.remainingArea - Number(this.form.area_semeada),
        'area',
        false
      )

      if (area == 0) {
        return 0
      }

      return area > 0
        ? formatDecimal(area, 2).replace(/,00$/, '').replace(/\.00$/, '')
        : this.$t('planting.area_greather_than_plot')
    },
    getLocale() {
      return currentLocale()
    },
    date_emergencia_formatted() {
      return this.form.date_emergencia
        ? this.form.date_emergencia.formatDate()
        : null
    },
    date_realizada_formatted() {
      return this.form.date_realizada
        ? this.form.date_realizada.formatDate()
        : null
    },
    areaTalhao() {
      return convertToTargetUnit(this.form.talhao.area, 'area')
    },
    totalSemeado() {
      if (!this.form.area_semeada || !this.form.quantidade_semeada) return 0

      return convertToTargetUnit(
        this.form.area_semeada * this.form.quantidade_semeada,
        'weight_kg'
      )
    },
    minDate() {
      if (!this.currentFarm || !this.currentSeasonCrop?.start_period) {
        return null
      }

      if (!this.isSoybean) {
        return this.setWheatMinSowingTime()
      }

      return this.currentSeasonCrop?.start_period
    },
    maxDate() {
      if (!this.currentFarm || !this.currentSeasonCrop?.end_period) {
        return null
      }

      const currentDay = moment()
      // XXX - é removido os dias de tolerancia para que a data de emergencia fique dentro do limite
      const finalSowingTime = moment(
        this.currentSeasonCrop?.end_period
      ).subtract(this.tolerancia, 'days')

      return finalSowingTime.isSameOrBefore(currentDay) ||
        this.currentUser?.is_qa_mode_enabled ||
        this.$toggle('DEBUG_MODE_ENABLE')
        ? finalSowingTime
        : currentDay
    },
    isRecommendedDate() {
      return !this.notRecommendedDates(this.form.date_realizada)
    },
    isRecommendedSeed() {
      return this.form?.semente?.recomendado ?? false
    },
    maxEmergencyDate() {
      const MaxDate = moment(
        Math.min(
          moment(this.form.date_realizada).add(30, 'days'),
          moment(this.currentSeasonCrop?.end_period)
        )
      )
      const currentDay = moment()

      return MaxDate.isSameOrBefore(currentDay) ||
        this.currentUser?.is_qa_mode_enabled ||
        this.$toggle('DEBUG_MODE_ENABLE')
        ? MaxDate
        : currentDay
    },
    minDateEmergencia() {
      return moment(this.form.date_realizada).add(1, 'days')
    },
    hasSpraying() {
      return this.semeadura?.total_sprayings > 0
    },
    isEditing() {
      return !!this.semeadura
    },
    isEmergencyDateDisabled() {
      return (
        !this.form.semente ||
        !this.form.date_realizada ||
        this.loadingDateEmergencia ||
        this.hasSpraying
      )
    },
    isSeedingDateDisabled() {
      return !this.form.semente || this.hasSpraying
    },
    hasPlotSelected() {
      return Boolean(this.form?.talhao?.id)
    },
    seedsInfo() {
      return this.form.semente?.recomendado ? true : false
    },
    sementesFilterPlanned() {
      const planned = []
      const notPlanned = []

      this.sementes.filter((item) => {
        if (item.added) {
          planned.push(item)
        } else {
          notPlanned.push(item)
        }
      })

      return [
        { header: this.$t('variety.planned') },
        ...planned,
        { header: this.$t('variety.not_planned') },
        ...notPlanned,
      ]
    },
  },

  methods: {
    ...mapActions('semeaduras', [
      'modalAreaRestante',
      'getSeedsBySelectedCrop',
      'modalFazenda',
      'modalDataEmergencia',
      'modalSave',
      'loadSowingsFilter',
    ]),
    ...mapActions('plot', ['getPlots']),

    async setSeedClasses() {
      const response = await api.seedClasses.getSeedClasses()

      const seedClasses = response.data.map((seedClass) => {
        return {
          ...seedClass,
          value: this.$t(seedClass.messageKey),
        }
      })
      this.seedClasses = [
        {
          id: null,
          value: this.$t('seed.select_seed_class'),
        },
        ...seedClasses,
      ]
    },

    async setSeederSystems() {
      const response = await api.seederSystems.getSeederSystems()

      const seederSystems = response.data.map((seederSystem) => {
        return {
          ...seederSystem,
          value: this.$t(seederSystem.messageKey),
        }
      })
      this.seederSystems = [
        {
          id: null,
          value: this.$t('seed.select_seeder_system'),
        },
        ...seederSystems,
      ]
    },

    setWheatMinSowingTime() {
      return moment(this.currentSeasonCrop?.start_period)
    },

    notRecommendedDates(date) {
      if (!this.isSoybean || !this.isRecommendedSeed || this.isUSA) {
        return false
      }

      if (!this.form.semente) return false

      const { semeadura_ini, semeadura_fim } = this.form.semente

      const moment_semeadura_ini = moment(semeadura_ini, 'YYYY-MM-DD')
      const moment_semeadura_fim = moment(semeadura_fim, 'YYYY-MM-DD')
      const isNotInRecommendedInterval = !moment(date).isBetween(
        moment_semeadura_ini,
        moment_semeadura_fim,
        null,
        '[]'
      )
      return isNotInRecommendedInterval && this.allowedDates(date)
    },

    checkPlot(selectedItem) {
      if (selectedItem && selectedItem.enabled_sowing === 0) {
        this.form.talhao = null

        this.$nextTick(() => {
          this.selectKey += 1
        })

        this.dialogUnusedArea = true
      } else {
        this.form.talhao = selectedItem
        this.modalAreaRestante(selectedItem)
      }
    },

    openConfigArea() {
      this.dialogConfigArea = true
      this.closeModalUnusedArea()
    },
    closeModalUnusedArea() {
      this.dialogUnusedArea = false
    },
    closeModalConfigArea() {
      const params = {
        farmId: this.currentFarmId,
        params: 'created_at:desc&enabled=true',
      }
      this.getPlots(params)
      this.dialogConfigArea = false
    },

    async load() {
      this.loadingSemeadura = true

      await this.getSeedsBySelectedCrop(this.currentFarmId)
      const params = {
        farmId: this.currentFarmId,
        params: 'created_at:desc&enabled=true',
      }
      await this.getPlots(params)

      if (this.semeadura) {
        await this.loadSowingsFilter()
        this.populateForm()
      }

      this.sementesSelect = this.sementes.map((semente) => {
        if (semente.crop_id == 2) {
          semente.disabled = semente.recomendado != 1
        }
        return semente
      })

      this.loadingSemeadura = false
    },

    populateForm() {
      if (!this.semeadura) {
        this.form.semente = null
        return
      }

      this.form.talhao = this.plots.find(
        (t) => t.id == this.semeadura.talhao_id
      )

      this.modalAreaRestante(this.form.talhao)

      this.form.semente = this.sementes.find(
        (s) => s.id == this.semeadura.semente_id
      )

      this.form.quantidade_semeada = this.semeadura.quantidade

      this.form.area_semeada = this.semeadura.area

      this.form.date_realizada = this.formatDateISO(
        this.semeadura.data_semeadura
      )
      this.form.date_emergencia = this.formatDateISO(
        this.semeadura.data_emergencia
      )

      this.form.origem = this.semeadura.origem
      this.form.estimated_cycle =
        this.semeadura.estimated_cycle ?? this.form.semente.ciclo_dias
      this.form.seederSystemId = this.semeadura.seeder_system_id
      this.form.seedClassId = this.semeadura.seed_class_id
      this.form.germinative_power = this.semeadura.germinative_power
      this.form.seed_vigor = this.semeadura.seed_vigor
      this.form.line_spacing = this.semeadura.line_spacing
      this.form.population_lines = this.semeadura.population_lines
      this.form.operating_speed = this.semeadura.operating_speed
      this.form.seed_treatment = this.semeadura.seed_treatment
      this.form.inoculant = this.semeadura.inoculant
    },
    formatDateISO(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    allowedDates(date) {
      return moment(date).isBetween(this.minDate, this.maxDate, null, '[]')
    },
    validateFirstStep() {
      this.trySubmit = true
      this.$v.form.semente.$touch()
      this.$v.form.estimated_cycle.$touch()
      this.$v.form.germinative_power.$touch()
      this.$v.form.seed_vigor.$touch()

      if (
        this.$v.form.semente.$invalid ||
        this.$v.form.estimated_cycle.$invalid ||
        this.$v.form.germinative_power.$invalid ||
        this.$v.form.seed_vigor.$invalid
      ) {
        return
      }
      this.step = 2
      this.trySubmit = false
      logEvent(events.sowings.clickedButton_createPlantingNext)
    },
    verifyFormValidation() {
      this.trySubmit = true
      this.$v.form.$touch()
      return this.$v.form.$invalid
    },
    async submit() {
      if (this.verifyFormValidation()) {
        return
      }
      this.loadingSubmit = true
      try {
        await this.modalSave({
          semente_id: this.form.semente.id,
          fazenda_id: this.currentFarmId,
          talhao_id: this.form.talhao.id,
          seed_class_id: this.form.seedClassId,
          seeder_system_id: this.form.seederSystemId,
          area_semeada: this.form.area_semeada,
          quantidade_semeada: this.form.quantidade_semeada,
          date_realizada: this.form.date_realizada,
          date_emergencia: this.form.date_emergencia,
          origem: this.form.origem,
          id: this.semeadura ? this.semeadura.id : null,
          estimated_cycle: this.form.estimated_cycle,
          germinative_power: this.form.germinative_power,
          seed_vigor: this.form.seed_vigor,
          line_spacing: this.form.line_spacing,
          population_lines: this.form.population_lines,
          operating_speed: this.form.operating_speed,
          seed_treatment: this.form.seed_treatment,
          inoculant: this.form.inoculant,
        })
        if (this.semeadura?.id)
          this.$root.$emit(
            'notify',
            'warning',
            this.$t('semeadura.cadastro.warning')
          )
        else this.$root.$emit('notify', 'success', this.$t('succesfuly_saved'))

        logEvent(events.sowings.clickedButton_createPlantingSave)
        this.$emit('reload-sowing-list')
      } catch (error) {
        if (error.response && error.response.data)
          this.$root.$emit('notify', 'error', this.$t(error.response.data.key))
        else this.$root.$emit('notify', 'error', error)
      } finally {
        this.loadingSubmit = false
        this.close()
      }
    },
    close() {
      this.$v.$reset()
      this.step = 1
      this.seedClass = null
      this.seederSystem = null
      this.form = { ...initialFormState }
      logEvent(events.sowings.createPlanting_close)
      this.$emit('close')
    },
    getErrors(inputName) {
      const formValidations = this.$options?.validations()?.form
      if (!formValidations) {
        return []
      }
      const rules = Object.keys(formValidations[inputName] ?? {})
      return rules
        .map((rule) => {
          const input = this.$v?.form[inputName]
          if (input && !input[rule]) {
            const params = input?.$params[rule]
            if (rule === 'required' && !this.trySubmit) {
              return null
            }
            return this.$t(params?.messageKey, params?.properties) ?? null
          }
        })
        .filter((messageKey) => messageKey)
    },
    updateCycle(seed) {
      this.form.estimated_cycle = seed.ciclo_dias
    },
  },

  watch: {
    dialog: {
      handler(isOpened) {
        if (isOpened) {
          logEvent(events.sowings.clickedButton_createPlanting)
          this.load()
        }
      },
      immediate: true,
    },

    async 'form.date_realizada'(val) {
      if (!val) return null

      // Verifica se a data selecionada esta a menos de 6 dias da data atual
      const currentDate = moment()
      const selectedDate = moment(val)
      const daysDifference = currentDate.diff(selectedDate, 'days')

      if (daysDifference < 6) {
        this.showWarningModal = true
      }

      this.loadingDateEmergencia = true
      await this.modalDataEmergencia(val)
      this.loadingDateEmergencia = false

      if (
        this.form.date_emergencia === null ||
        this.form.date_emergencia < val
      ) {
        this.form.date_emergencia = this.formatDateISO(this.emergencia)

        if (this.form.date_emergencia > this.formatDateISO(moment())) {
          this.form.date_emergencia = this.formatDateISO(moment())
        }
      }

      const today = moment().format('YYYY-MM-DD')
      const maxEmergencyDate = moment(this.maxEmergencyDate).format(
        'YYYY-MM-DD'
      )

      if (maxEmergencyDate === today && val === today) {
        this.form.date_realizada = this.formatDateISO(
          moment().subtract(1, 'days')
        )
      }
    },
  },
}
</script>

<style>
a:link {
  text-decoration: none;
}

.added {
  color: grey;
}
</style>
